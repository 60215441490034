<section class="bg-body-tertiary pb-5" aria-label="FAQ">
  <div class="container">
    @if (showBudget) {
    <div class="semi-title-badget mt-5">AYUDA</div>
    }
    <h2 class="pt-3 pb-1 pb-md-4 pb-lg-4">Preguntas frecuentes</h2>
    <div>
      <div class="accordion accordion-flush" id="accordionFAQ">
        <div class="accordion-item">
          <h3 class="accordion-header">
            <button class="accordion-button collapsed px-0" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              ¿Qué es Asegurús?
            </button>
          </h3>
          <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
            <div class="accordion-body px-0">
              Somos una insurtech que cuenta con un equipo de especialistas para asesorarte
              con la mejor opción entre cientos de alternativas para tus seguros. Contamos con más de 24.000 consultas
              resueltas al año y asistimos a nuestros clientes en la contratación y durante toda la vigencia de la
              póliza.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h3 class="accordion-header">
            <button class="accordion-button collapsed px-0" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
              ¿Asegurús está habilitada?
            </button>
          </h3>
          <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
            <div class="accordion-body px-0">
              Sí, somos un bróker de seguros debidamente constituido con marca registrada en la Superintendencia de
              Seguros de la Nación.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h3 class="accordion-header">
            <button class="accordion-button collapsed px-0" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
              ¿Es seguro ingresar mis datos en la web de Asegurús?
            </button>
          </h3>
          <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
            <div class="accordion-body px-0">
              Sí, tus datos están bajo confidencialidad y un sistema de certificados SSL. En
              Asegurús comprendemos la importancia de mantener la privacidad de tu información personal y nunca
              divulgaremos datos personales de nuestros clientes o personas que realizan consultas.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h3 class="accordion-header">
            <button class="accordion-button collapsed px-0" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
              ¿Con qué forma de pago cuentan?
            </button>
          </h3>
          <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
            <div class="accordion-body px-0">
              Operamos con tarjetas de crédito o débito desde tu cuenta bancaria.
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h3 class="accordion-header">
            <button class="accordion-button collapsed px-0" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFifth" aria-expanded="false" aria-controls="flush-collapseFifth">
              ¿Cuándo me debitan la cuota del seguro?
            </button>
          </h3>
          <div id="flush-collapseFifth" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
            <div class="accordion-body px-0">
              La fecha exacta depende de la compañía elegida y el medio de pago.
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h3 class="accordion-header">
            <button class="accordion-button collapsed px-0" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
              ¿Desde que año aseguran autos?
            </button>
          </h3>
          <div id="flush-collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
            <div class="accordion-body px-0">
              Contamos con coberturas de vehículos de hasta 30 años de antigüedad.
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h3 class="accordion-header">
            <button class="accordion-button collapsed px-0" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
              ¿Cuál es la ventaja de contratar un seguro con nosotros?
            </button>
          </h3>
          <div id="flush-collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
            <div class="accordion-body px-0">
              Contratando un seguro con Asegurús Bróker te asegurás el mejor precio y servicio. Operamos con más de 10
              compañías de primer nivel, contamos con los mejores descuentos y nuestro equipo de asesores están
              sumamente capacitados. Además, contamos con asesoramiento en toda la vigencia de tu póliza.
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h3 class="accordion-header">
            <button class="accordion-button collapsed px-0" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
              ¿Qué necesito saber antes de cotizar?
            </button>
          </h3>
          <div id="flush-collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
            <div class="accordion-body px-0">
              Marca, modelo, año de fabricación de tu vehículo y zona de residencia. De este último punto dependerá en
              gran medida el valor de tu seguro.
            </div>
          </div>
        </div>

        <div class="accordion-item"></div>



      </div>
    </div>
  </div>
</section>
