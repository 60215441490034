import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.css',
})
export class FaqComponent implements OnInit {
  router = inject(Router);
  showBudget = false;

  ngOnInit(): void {
    const url = this.router.url;
    if (url === '/') this.showBudget = true;
  }
}
